export const studentData = [
  {
    className: "Six",
    maleStudents: 300,
    femaleStudents: 50,
  },
  {
    className: "Seven",
    maleStudents: 90,
    femaleStudents: 40,
  },
  {
    className: "Eight",
    maleStudents: 90,
    femaleStudents: 40,
  },
  {
    className: "Nine",
    maleStudents: 90,
    femaleStudents: 40,
  },
  {
    className: "Ten",
    maleStudents: 90,
    femaleStudents: 40,
  },
];
